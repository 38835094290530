<template>
    <FullscreenLayout>
        <div class="px-6 py-4 flex min-h-screen ">
            <div class="my-auto w-full">
                <form 
                    class="mx-auto mt-16 block w-full max-w-sm px-6 py-8 bg-white rounded-2xl shadow-xl"
                    @submit.prevent="selectUser"
                >
                    <select 
                        v-model="selectedUser"
                        required
                        placeholder="Enter your password"
                        class="mt-1 w-full form-select"
                    >
                        <option
                            v-for="user in users"
                            :key="user.id"
                            :value="user.id"
                        >
                            {{ user?.myCompany?.name }}
                        </option>
                    </select>

                    <button
                        type="submit"
                        class="mt-8 px-6 py-2 w-full text-white font-medium bg-indigo-500 rounded-lg"
                    >
                        Continue
                    </button>
                </form>
            </div>
        </div>
    </FullscreenLayout>
</template>

<script>
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';

    import FullscreenLayout from '@/layouts/fullscreen';
    
    import { state, actions } from '@/store';

    export default {
        name: 'Login',
        components: {
            FullscreenLayout
        },
        setup() {
            const router = useRouter();
            const forward = computed(() => state.forward);
            
            const users = computed(() => state.users);
            const user = computed(() => state.user);

            function next() {
                const route = forward.value ? { ...forward.value } : { name: 'Home' };
                router.push(route);
            }

            const selectedUser = ref(user.value.id);
            function selectUser() {
                const id = selectedUser.value;
                const us = users.value.find(u => u.id === id);

                actions.setUser(us);
                next();
            }

            


            return {
                forward,

                users,
                user,
                next,

                selectedUser,
                selectUser
            };
        }
    }
</script>